import React, { Component } from 'react';
import './SelectApiPage.css';
import Logo from '../logo.svg';
import AppFooter from '../AppFooter/AppFooter';
import SelectApi from '../SelectApi/SelectApi';

class SelectApiPage extends Component {

  handleChange = selectedApi => {
    this.props.history.push(selectedApi.value);
  };
  componentDidMount(){
    document.title = window.applicationName;
  }

  render() {
    return (
      <div>
        <div className="search-wrapper">
          <div>
            <img src={Logo} alt="Kuehne+Nagel" style={{ maxWidth: '60%' }}/>
            <h1 className='heading'>Welcome to { window.applicationName } </h1>

            <SelectApi
              autoFocus="true"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <AppFooter/>
      </div>
    );
  }
}

export default SelectApiPage;
