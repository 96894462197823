import React, { Component } from 'react';
import Select from 'react-select';
import slugify from 'slugify';

class SelectApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableApis: window.apiSpecs.map(item => ({ value: slugify(item.value).toLowerCase(), label: item.label, url: item.url })),
    }
  }

  render() {
    return (
        <React.Fragment>
        <Select
            className="select__api"
            autoFocus={this.props.autoFocus}
            value={this.props.value}
            onChange={this.props.onChange}
            options={this.state.availableApis}
            noOptionsMessage={() => 'No API(s) found'}
            placeholder={'Select an API...'}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                primary: '#112f52',
              },
            })}
          />
          </React.Fragment>
    );
  }
}

export default SelectApi;
