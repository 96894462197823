import { Component } from 'react';
import './AppFooter.css';

class AppFooter extends Component {
  render() {
    return (
      ''
    );
  }
}

export default AppFooter;
