import React, { Component } from 'react';
import './RedocPage.css';
import Logo from '../logo.svg';
import { RedocStandalone } from 'redoc';
import slugify from 'slugify';
import { Link } from 'react-router-dom'
import SelectApi from '../SelectApi/SelectApi';

class RedocPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableApis: window.apiSpecs.map(item => ({ value: slugify(item.value).toLowerCase(), label: item.label, url: item.url })),
      activeApi: {
        url: ''
      }
    }
    const activeApiFromQuery = this.state.availableApis.find(element => element.value === this.props.match.params.api);
    if (activeApiFromQuery) {
      this.state.activeApi = activeApiFromQuery;
      document.title = activeApiFromQuery.label ? activeApiFromQuery.label + ' - ' + window.applicationName : window.applicationName;
    } else {
      this.props.history.push('/');
      document.title = window.applicationName;
    }
  }

  handleChange = selectedApi => {
    this.setState({
      activeApi: selectedApi
    })
    this.props.history.push(selectedApi.value);
    document.title = selectedApi.label ? selectedApi.label + ' - ' + window.applicationName : window.applicationName;
  };

  render() {
    return (
      <div>
        <header className="RedocPage-header">
          <Link to={'/'}>
            <img src={Logo} alt="Kuehne+Nagel" height="250" />
          </Link>

          <SelectApi
            className="select"
            value={this.state.activeApi}
            onChange={this.handleChange}
          />
        </header>
        <section className="container__redoc">
          <RedocStandalone
            specUrl={this.state.activeApi.url}
            options={{
              nativeScrollbars: true,
              scrollYOffset: 60,
              hideDownloadButton: false,
              theme: {
                colors: { primary: { main: '#003369' } },
                sidebar: {
                  backgroundColor: '#003369',
                  textColor: '#fff',
                  activeTextColor: '#0099da'
                },
                typography: {
                  fontSize: '0.9em',
                  fontFamily: "SuisseIntl, sans-serif",
                  optimizeSpeed: true,
                  fontWeight: '100',
                  headings: {
                    fontFamily: "SuisseIntl-Bold, sans-serif",
                    fontWeight: '200'
                  }
                }
              },
            }}
          />
        </section>
      </div>
    );
  }
}

export default RedocPage;
